<template>
  <div>
    <img class="w-100" src="../../assets/img/about-1.jpg" />
    <img class="w-100" src="../../assets/img/about-2.jpg" />
    <img class="w-100" src="../../assets/img/about-3.jpg" />
    <img class="w-100" src="../../assets/img/about-4.jpg" />
    <img class="w-100" src="../../assets/img/about-5.jpg" />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {}
}
</script>